/* stylelint-disable max-line-length */
/*
 * TODO: we should use/reuse/extend only from the following list of colors (affable.ai colors palette)
 * added Figma plugin to maintain this (Export  styles to CSS variables)
 * affable design system maintained here: https://www.figma.com/file/52QrduudIvhcHmVVkDtRgo/Affable-Design-System?node-id=1%3A270
 */
@import '@angular/cdk/overlay-prebuilt.css';
@import 'flexboxgrid.min.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300&display=swap');

* {
  font-family: 'Open Sans';
}
*:focus-visible {
  outline: none;
}

:root {
  /* Colors */
  --base-900: #151a30;
  --base-800: #192038;
  --base-700: #222b45;
  --base-600: #2e3a59;
  --base-500: #8f9bb3;
  --base-400: #c5cee0;
  --base-300: #e4e9f2;
  --base-200: #edf1f7;
  --base-100: #f7f9fc;
  --shadow: #dfe3eb;
  --white: #ffffff;
  --black: #151a30;
  --success: #00d68f;
  --warning: #f47b47;
  --danger: #dc3545;
  --highlight: #72d887;
  --blue: #007bff;
  --bluish: #2f2e41;
  --purple: #7a6eff;
  --light-purple: #dfe1f4;
  --pink: #ea2490;
  --border: #dbdfe5;
  --disabled-base: #bfc8d8;
  --disabled-dark: #b8b8b8;
  --disabled-light: #dddddd;
  --background-light: #fafaf9;
  --background-tint: #eceff4;
  --background-base: #ebeff5;
  --text-background: #f5f6f7;
  --background-bg-3: #101426;
  --border-color: #bcc3cc;

  --line-base: #ebedef;
  --line-light: #d6dce5;
  --line-disabled: var(--disabled-base);

  --btn-active: var(--purple);
  --btn-hover: #a8a1ff;
  --btn-pressed: #494299;
  --btn-disabled: var(--disabled-base);

  --input-border: var(--disabled-base);

  --icon-active: var(--base-600);
  --icon-secondary: #989ead;
  --icon-disabled: var(--disabled-base);

  /* FONTS */
  --font-family: 'Open Sans';

  /* Sizes */
  --font-40: 40px;
  --font-24: 24px;
  --font-20: 20px;
  --font-18: 18px;
  --font-16: 16px;
  --font-14: 14px;
  --font-12: 12px;
  --font-11: 11px;
  --font-9: 9px;
  --font-6: 6px;

  --font-light: 300;
  --font-regular: 400;
  --font-regular-bold: 450;
  --font-medium: 500;
  --font-semibold: 600;
  --font-bold: 700;
  --font-extrabold: 800;
  --radius-10: 10px;
  --radius-6: 6px;
  --radius-4: 4px;
  --radius-circular: 50%;
}

/* TYPOGRAPHY */
.main-title {
  font-size: var(--font-24);
  font-weight: var(--font-semibold);
  color: var(--base-900);
}

.display {
  font-size: var(--font-20);
  font-weight: var(--font-bold);
  color: var(--base-900);
}

.heading-h1 {
  font-size: var(--font-18);
  font-weight: var(--font-bold);
  color: var(--base-600);
}

.heading-h2 {
  font-size: var(--font-16);
  font-weight: var(--font-semibold);
  color: var(--base-600);
}

.sub-heading-h1 {
  font-size: var(--font-14);
  font-weight: var(--font-bold);
  color: var(--base-900);
}

.sub-heading-h2 {
  font-size: var(--font-14);
  font-weight: var(--font-semibold);
  color: var(--base-900);
}

.body-1 {
  font-size: var(--font-16);
  font-weight: var(--font-regular);
  color: var(--base-600);
}

.body-2 {
  font-size: var(--font-14);
  font-weight: var(--font-regular);
  color: var(--base-600);
  line-height: 18px;
}

.body-3 {
  font-size: var(--font-12);
  font-weight: var(--font-semibold);
  color: var(--base-600);
}

.body-4 {
  font-size: var(--font-12);
  font-weight: var(--font-regular);
  color: var(--base-600);
}

.label {
  font-size: var(--font-12);
  font-weight: var(--font-bold);
  color: var(--base-900);
}

.caption {
  font-size: var(--font-11);
  font-weight: var(--font-semibold);
  color: var(--base-500);
}

.caption-2 {
  font-size: var(--font-9);
  font-weight: var(--font-bold);
  color: var(--white);
}

.placeholder-text {
  color: var(--base-500);
}

.link-text {
  color: var(--purple);
}

label > span.required {
  color: var(--danger);
}

div.story.row [class^='col-'] {
  margin-bottom: 24px;
}

.ai-modal {
  background: var(--white);
  border-radius: 6px;
}
