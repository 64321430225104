/**
  In the bellow we code defines four different mixins:
 "padding-classes", "margin-classes", "width-percentage-classes" and "opacity-classes".
  Please use the same file for any other mixins helpers !
 */
.ai-p0 {
  padding: 0px !important;
}

.ai-pl0 {
  padding-left: 0px !important;
}

.ai-pr0 {
  padding-right: 0px !important;
}

.ai-pt0 {
  padding-top: 0px !important;
}

.ai-pb0 {
  padding-bottom: 0px !important;
}

.ai-px0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ai-py0 {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.ai-p1 {
  padding: 1px !important;
}

.ai-pl1 {
  padding-left: 1px !important;
}

.ai-pr1 {
  padding-right: 1px !important;
}

.ai-pt1 {
  padding-top: 1px !important;
}

.ai-pb1 {
  padding-bottom: 1px !important;
}

.ai-px1 {
  padding-left: 1px !important;
  padding-right: 1px !important;
}

.ai-py1 {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.ai-p2 {
  padding: 2px !important;
}

.ai-pl2 {
  padding-left: 2px !important;
}

.ai-pr2 {
  padding-right: 2px !important;
}

.ai-pt2 {
  padding-top: 2px !important;
}

.ai-pb2 {
  padding-bottom: 2px !important;
}

.ai-px2 {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.ai-py2 {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.ai-p3 {
  padding: 3px !important;
}

.ai-pl3 {
  padding-left: 3px !important;
}

.ai-pr3 {
  padding-right: 3px !important;
}

.ai-pt3 {
  padding-top: 3px !important;
}

.ai-pb3 {
  padding-bottom: 3px !important;
}

.ai-px3 {
  padding-left: 3px !important;
  padding-right: 3px !important;
}

.ai-py3 {
  padding-top: 3px !important;
  padding-bottom: 3px !important;
}

.ai-p4 {
  padding: 4px !important;
}

.ai-pl4 {
  padding-left: 4px !important;
}

.ai-pr4 {
  padding-right: 4px !important;
}

.ai-pt4 {
  padding-top: 4px !important;
}

.ai-pb4 {
  padding-bottom: 4px !important;
}

.ai-px4 {
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.ai-py4 {
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}

.ai-p5 {
  padding: 5px !important;
}

.ai-pl5 {
  padding-left: 5px !important;
}

.ai-pr5 {
  padding-right: 5px !important;
}

.ai-pt5 {
  padding-top: 5px !important;
}

.ai-pb5 {
  padding-bottom: 5px !important;
}

.ai-px5 {
  padding-left: 5px !important;
  padding-right: 5px !important;
}

.ai-py5 {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.ai-p6 {
  padding: 6px !important;
}

.ai-pl6 {
  padding-left: 6px !important;
}

.ai-pr6 {
  padding-right: 6px !important;
}

.ai-pt6 {
  padding-top: 6px !important;
}

.ai-pb6 {
  padding-bottom: 6px !important;
}

.ai-px6 {
  padding-left: 6px !important;
  padding-right: 6px !important;
}

.ai-py6 {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.ai-p7 {
  padding: 7px !important;
}

.ai-pl7 {
  padding-left: 7px !important;
}

.ai-pr7 {
  padding-right: 7px !important;
}

.ai-pt7 {
  padding-top: 7px !important;
}

.ai-pb7 {
  padding-bottom: 7px !important;
}

.ai-px7 {
  padding-left: 7px !important;
  padding-right: 7px !important;
}

.ai-py7 {
  padding-top: 7px !important;
  padding-bottom: 7px !important;
}

.ai-p8 {
  padding: 8px !important;
}

.ai-pl8 {
  padding-left: 8px !important;
}

.ai-pr8 {
  padding-right: 8px !important;
}

.ai-pt8 {
  padding-top: 8px !important;
}

.ai-pb8 {
  padding-bottom: 8px !important;
}

.ai-px8 {
  padding-left: 8px !important;
  padding-right: 8px !important;
}

.ai-py8 {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.ai-p9 {
  padding: 9px !important;
}

.ai-pl9 {
  padding-left: 9px !important;
}

.ai-pr9 {
  padding-right: 9px !important;
}

.ai-pt9 {
  padding-top: 9px !important;
}

.ai-pb9 {
  padding-bottom: 9px !important;
}

.ai-px9 {
  padding-left: 9px !important;
  padding-right: 9px !important;
}

.ai-py9 {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
}

.ai-p10 {
  padding: 10px !important;
}

.ai-pl10 {
  padding-left: 10px !important;
}

.ai-pr10 {
  padding-right: 10px !important;
}

.ai-pt10 {
  padding-top: 10px !important;
}

.ai-pb10 {
  padding-bottom: 10px !important;
}

.ai-px10 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.ai-py10 {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

.ai-p11 {
  padding: 11px !important;
}

.ai-pl11 {
  padding-left: 11px !important;
}

.ai-pr11 {
  padding-right: 11px !important;
}

.ai-pt11 {
  padding-top: 11px !important;
}

.ai-pb11 {
  padding-bottom: 11px !important;
}

.ai-px11 {
  padding-left: 11px !important;
  padding-right: 11px !important;
}

.ai-py11 {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.ai-p12 {
  padding: 12px !important;
}

.ai-pl12 {
  padding-left: 12px !important;
}

.ai-pr12 {
  padding-right: 12px !important;
}

.ai-pt12 {
  padding-top: 12px !important;
}

.ai-pb12 {
  padding-bottom: 12px !important;
}

.ai-px12 {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.ai-py12 {
  padding-top: 12px !important;
  padding-bottom: 12px !important;
}

.ai-p13 {
  padding: 13px !important;
}

.ai-pl13 {
  padding-left: 13px !important;
}

.ai-pr13 {
  padding-right: 13px !important;
}

.ai-pt13 {
  padding-top: 13px !important;
}

.ai-pb13 {
  padding-bottom: 13px !important;
}

.ai-px13 {
  padding-left: 13px !important;
  padding-right: 13px !important;
}

.ai-py13 {
  padding-top: 13px !important;
  padding-bottom: 13px !important;
}

.ai-p14 {
  padding: 14px !important;
}

.ai-pl14 {
  padding-left: 14px !important;
}

.ai-pr14 {
  padding-right: 14px !important;
}

.ai-pt14 {
  padding-top: 14px !important;
}

.ai-pb14 {
  padding-bottom: 14px !important;
}

.ai-px14 {
  padding-left: 14px !important;
  padding-right: 14px !important;
}

.ai-py14 {
  padding-top: 14px !important;
  padding-bottom: 14px !important;
}

.ai-p15 {
  padding: 15px !important;
}

.ai-pl15 {
  padding-left: 15px !important;
}

.ai-pr15 {
  padding-right: 15px !important;
}

.ai-pt15 {
  padding-top: 15px !important;
}

.ai-pb15 {
  padding-bottom: 15px !important;
}

.ai-px15 {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.ai-py15 {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.ai-p16 {
  padding: 16px !important;
}

.ai-pl16 {
  padding-left: 16px !important;
}

.ai-pr16 {
  padding-right: 16px !important;
}

.ai-pt16 {
  padding-top: 16px !important;
}

.ai-pb16 {
  padding-bottom: 16px !important;
}

.ai-px16 {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.ai-py16 {
  padding-top: 16px !important;
  padding-bottom: 16px !important;
}

.ai-p17 {
  padding: 17px !important;
}

.ai-pl17 {
  padding-left: 17px !important;
}

.ai-pr17 {
  padding-right: 17px !important;
}

.ai-pt17 {
  padding-top: 17px !important;
}

.ai-pb17 {
  padding-bottom: 17px !important;
}

.ai-px17 {
  padding-left: 17px !important;
  padding-right: 17px !important;
}

.ai-py17 {
  padding-top: 17px !important;
  padding-bottom: 17px !important;
}

.ai-p18 {
  padding: 18px !important;
}

.ai-pl18 {
  padding-left: 18px !important;
}

.ai-pr18 {
  padding-right: 18px !important;
}

.ai-pt18 {
  padding-top: 18px !important;
}

.ai-pb18 {
  padding-bottom: 18px !important;
}

.ai-px18 {
  padding-left: 18px !important;
  padding-right: 18px !important;
}

.ai-py18 {
  padding-top: 18px !important;
  padding-bottom: 18px !important;
}

.ai-p19 {
  padding: 19px !important;
}

.ai-pl19 {
  padding-left: 19px !important;
}

.ai-pr19 {
  padding-right: 19px !important;
}

.ai-pt19 {
  padding-top: 19px !important;
}

.ai-pb19 {
  padding-bottom: 19px !important;
}

.ai-px19 {
  padding-left: 19px !important;
  padding-right: 19px !important;
}

.ai-py19 {
  padding-top: 19px !important;
  padding-bottom: 19px !important;
}

.ai-p20 {
  padding: 20px !important;
}

.ai-pl20 {
  padding-left: 20px !important;
}

.ai-pr20 {
  padding-right: 20px !important;
}

.ai-pt20 {
  padding-top: 20px !important;
}

.ai-pb20 {
  padding-bottom: 20px !important;
}

.ai-px20 {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.ai-py20 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.ai-p21 {
  padding: 21px !important;
}

.ai-pl21 {
  padding-left: 21px !important;
}

.ai-pr21 {
  padding-right: 21px !important;
}

.ai-pt21 {
  padding-top: 21px !important;
}

.ai-pb21 {
  padding-bottom: 21px !important;
}

.ai-px21 {
  padding-left: 21px !important;
  padding-right: 21px !important;
}

.ai-py21 {
  padding-top: 21px !important;
  padding-bottom: 21px !important;
}

.ai-p22 {
  padding: 22px !important;
}

.ai-pl22 {
  padding-left: 22px !important;
}

.ai-pr22 {
  padding-right: 22px !important;
}

.ai-pt22 {
  padding-top: 22px !important;
}

.ai-pb22 {
  padding-bottom: 22px !important;
}

.ai-px22 {
  padding-left: 22px !important;
  padding-right: 22px !important;
}

.ai-py22 {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
}

.ai-p23 {
  padding: 23px !important;
}

.ai-pl23 {
  padding-left: 23px !important;
}

.ai-pr23 {
  padding-right: 23px !important;
}

.ai-pt23 {
  padding-top: 23px !important;
}

.ai-pb23 {
  padding-bottom: 23px !important;
}

.ai-px23 {
  padding-left: 23px !important;
  padding-right: 23px !important;
}

.ai-py23 {
  padding-top: 23px !important;
  padding-bottom: 23px !important;
}

.ai-p24 {
  padding: 24px !important;
}

.ai-pl24 {
  padding-left: 24px !important;
}

.ai-pr24 {
  padding-right: 24px !important;
}

.ai-pt24 {
  padding-top: 24px !important;
}

.ai-pb24 {
  padding-bottom: 24px !important;
}

.ai-px24 {
  padding-left: 24px !important;
  padding-right: 24px !important;
}

.ai-py24 {
  padding-top: 24px !important;
  padding-bottom: 24px !important;
}

.ai-p25 {
  padding: 25px !important;
}

.ai-pl25 {
  padding-left: 25px !important;
}

.ai-pr25 {
  padding-right: 25px !important;
}

.ai-pt25 {
  padding-top: 25px !important;
}

.ai-pb25 {
  padding-bottom: 25px !important;
}

.ai-px25 {
  padding-left: 25px !important;
  padding-right: 25px !important;
}

.ai-py25 {
  padding-top: 25px !important;
  padding-bottom: 25px !important;
}

.ai-m1 {
  margin: 1px !important;
}

.ai-ml1 {
  margin-left: 1px !important;
}

.ai-mr1 {
  margin-right: 1px !important;
}

.ai-mt1 {
  margin-top: 1px !important;
}

.ai-mb1 {
  margin-bottom: 1px !important;
}

.ai-mx1 {
  margin-left: 1px !important;
  margin-right: 1px !important;
}

.ai-my1 {
  margin-top: 1px !important;
  margin-bottom: 1px !important;
}

.ai-m2 {
  margin: 2px !important;
}

.ai-ml2 {
  margin-left: 2px !important;
}

.ai-mr2 {
  margin-right: 2px !important;
}

.ai-mt2 {
  margin-top: 2px !important;
}

.ai-mb2 {
  margin-bottom: 2px !important;
}

.ai-mx2 {
  margin-left: 2px !important;
  margin-right: 2px !important;
}

.ai-my2 {
  margin-top: 2px !important;
  margin-bottom: 2px !important;
}

.ai-m3 {
  margin: 3px !important;
}

.ai-ml3 {
  margin-left: 3px !important;
}

.ai-mr3 {
  margin-right: 3px !important;
}

.ai-mt3 {
  margin-top: 3px !important;
}

.ai-mb3 {
  margin-bottom: 3px !important;
}

.ai-mx3 {
  margin-left: 3px !important;
  margin-right: 3px !important;
}

.ai-my3 {
  margin-top: 3px !important;
  margin-bottom: 3px !important;
}

.ai-m4 {
  margin: 4px !important;
}

.ai-ml4 {
  margin-left: 4px !important;
}

.ai-mr4 {
  margin-right: 4px !important;
}

.ai-mt4 {
  margin-top: 4px !important;
}

.ai-mb4 {
  margin-bottom: 4px !important;
}

.ai-mx4 {
  margin-left: 4px !important;
  margin-right: 4px !important;
}

.ai-my4 {
  margin-top: 4px !important;
  margin-bottom: 4px !important;
}

.ai-m5 {
  margin: 5px !important;
}

.ai-ml5 {
  margin-left: 5px !important;
}

.ai-mr5 {
  margin-right: 5px !important;
}

.ai-mt5 {
  margin-top: 5px !important;
}

.ai-mb5 {
  margin-bottom: 5px !important;
}

.ai-mx5 {
  margin-left: 5px !important;
  margin-right: 5px !important;
}

.ai-my5 {
  margin-top: 5px !important;
  margin-bottom: 5px !important;
}

.ai-m6 {
  margin: 6px !important;
}

.ai-ml6 {
  margin-left: 6px !important;
}

.ai-mr6 {
  margin-right: 6px !important;
}

.ai-mt6 {
  margin-top: 6px !important;
}

.ai-mb6 {
  margin-bottom: 6px !important;
}

.ai-mx6 {
  margin-left: 6px !important;
  margin-right: 6px !important;
}

.ai-my6 {
  margin-top: 6px !important;
  margin-bottom: 6px !important;
}

.ai-m7 {
  margin: 7px !important;
}

.ai-ml7 {
  margin-left: 7px !important;
}

.ai-mr7 {
  margin-right: 7px !important;
}

.ai-mt7 {
  margin-top: 7px !important;
}

.ai-mb7 {
  margin-bottom: 7px !important;
}

.ai-mx7 {
  margin-left: 7px !important;
  margin-right: 7px !important;
}

.ai-my7 {
  margin-top: 7px !important;
  margin-bottom: 7px !important;
}

.ai-m8 {
  margin: 8px !important;
}

.ai-ml8 {
  margin-left: 8px !important;
}

.ai-mr8 {
  margin-right: 8px !important;
}

.ai-mt8 {
  margin-top: 8px !important;
}

.ai-mb8 {
  margin-bottom: 8px !important;
}

.ai-mx8 {
  margin-left: 8px !important;
  margin-right: 8px !important;
}

.ai-my8 {
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}

.ai-m9 {
  margin: 9px !important;
}

.ai-ml9 {
  margin-left: 9px !important;
}

.ai-mr9 {
  margin-right: 9px !important;
}

.ai-mt9 {
  margin-top: 9px !important;
}

.ai-mb9 {
  margin-bottom: 9px !important;
}

.ai-mx9 {
  margin-left: 9px !important;
  margin-right: 9px !important;
}

.ai-my9 {
  margin-top: 9px !important;
  margin-bottom: 9px !important;
}

.ai-m10 {
  margin: 10px !important;
}

.ai-ml10 {
  margin-left: 10px !important;
}

.ai-mr10 {
  margin-right: 10px !important;
}

.ai-mt10 {
  margin-top: 10px !important;
}

.ai-mb10 {
  margin-bottom: 10px !important;
}

.ai-mx10 {
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.ai-my10 {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.ai-m11 {
  margin: 11px !important;
}

.ai-ml11 {
  margin-left: 11px !important;
}

.ai-mr11 {
  margin-right: 11px !important;
}

.ai-mt11 {
  margin-top: 11px !important;
}

.ai-mb11 {
  margin-bottom: 11px !important;
}

.ai-mx11 {
  margin-left: 11px !important;
  margin-right: 11px !important;
}

.ai-my11 {
  margin-top: 11px !important;
  margin-bottom: 11px !important;
}

.ai-m12 {
  margin: 12px !important;
}

.ai-ml12 {
  margin-left: 12px !important;
}

.ai-mr12 {
  margin-right: 12px !important;
}

.ai-mt12 {
  margin-top: 12px !important;
}

.ai-mb12 {
  margin-bottom: 12px !important;
}

.ai-mx12 {
  margin-left: 12px !important;
  margin-right: 12px !important;
}

.ai-my12 {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.ai-m13 {
  margin: 13px !important;
}

.ai-ml13 {
  margin-left: 13px !important;
}

.ai-mr13 {
  margin-right: 13px !important;
}

.ai-mt13 {
  margin-top: 13px !important;
}

.ai-mb13 {
  margin-bottom: 13px !important;
}

.ai-mx13 {
  margin-left: 13px !important;
  margin-right: 13px !important;
}

.ai-my13 {
  margin-top: 13px !important;
  margin-bottom: 13px !important;
}

.ai-m14 {
  margin: 14px !important;
}

.ai-ml14 {
  margin-left: 14px !important;
}

.ai-mr14 {
  margin-right: 14px !important;
}

.ai-mt14 {
  margin-top: 14px !important;
}

.ai-mb14 {
  margin-bottom: 14px !important;
}

.ai-mx14 {
  margin-left: 14px !important;
  margin-right: 14px !important;
}

.ai-my14 {
  margin-top: 14px !important;
  margin-bottom: 14px !important;
}

.ai-m15 {
  margin: 15px !important;
}

.ai-ml15 {
  margin-left: 15px !important;
}

.ai-mr15 {
  margin-right: 15px !important;
}

.ai-mt15 {
  margin-top: 15px !important;
}

.ai-mb15 {
  margin-bottom: 15px !important;
}

.ai-mx15 {
  margin-left: 15px !important;
  margin-right: 15px !important;
}

.ai-my15 {
  margin-top: 15px !important;
  margin-bottom: 15px !important;
}

.ai-m16 {
  margin: 16px !important;
}

.ai-ml16 {
  margin-left: 16px !important;
}

.ai-mr16 {
  margin-right: 16px !important;
}

.ai-mt16 {
  margin-top: 16px !important;
}

.ai-mb16 {
  margin-bottom: 16px !important;
}

.ai-mx16 {
  margin-left: 16px !important;
  margin-right: 16px !important;
}

.ai-my16 {
  margin-top: 16px !important;
  margin-bottom: 16px !important;
}

.ai-m17 {
  margin: 17px !important;
}

.ai-ml17 {
  margin-left: 17px !important;
}

.ai-mr17 {
  margin-right: 17px !important;
}

.ai-mt17 {
  margin-top: 17px !important;
}

.ai-mb17 {
  margin-bottom: 17px !important;
}

.ai-mx17 {
  margin-left: 17px !important;
  margin-right: 17px !important;
}

.ai-my17 {
  margin-top: 17px !important;
  margin-bottom: 17px !important;
}

.ai-m18 {
  margin: 18px !important;
}

.ai-ml18 {
  margin-left: 18px !important;
}

.ai-mr18 {
  margin-right: 18px !important;
}

.ai-mt18 {
  margin-top: 18px !important;
}

.ai-mb18 {
  margin-bottom: 18px !important;
}

.ai-mx18 {
  margin-left: 18px !important;
  margin-right: 18px !important;
}

.ai-my18 {
  margin-top: 18px !important;
  margin-bottom: 18px !important;
}

.ai-m19 {
  margin: 19px !important;
}

.ai-ml19 {
  margin-left: 19px !important;
}

.ai-mr19 {
  margin-right: 19px !important;
}

.ai-mt19 {
  margin-top: 19px !important;
}

.ai-mb19 {
  margin-bottom: 19px !important;
}

.ai-mx19 {
  margin-left: 19px !important;
  margin-right: 19px !important;
}

.ai-my19 {
  margin-top: 19px !important;
  margin-bottom: 19px !important;
}

.ai-m20 {
  margin: 20px !important;
}

.ai-ml20 {
  margin-left: 20px !important;
}

.ai-mr20 {
  margin-right: 20px !important;
}

.ai-mt20 {
  margin-top: 20px !important;
}

.ai-mb20 {
  margin-bottom: 20px !important;
}

.ai-mx20 {
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.ai-my20 {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.ai-m21 {
  margin: 21px !important;
}

.ai-ml21 {
  margin-left: 21px !important;
}

.ai-mr21 {
  margin-right: 21px !important;
}

.ai-mt21 {
  margin-top: 21px !important;
}

.ai-mb21 {
  margin-bottom: 21px !important;
}

.ai-mx21 {
  margin-left: 21px !important;
  margin-right: 21px !important;
}

.ai-my21 {
  margin-top: 21px !important;
  margin-bottom: 21px !important;
}

.ai-m22 {
  margin: 22px !important;
}

.ai-ml22 {
  margin-left: 22px !important;
}

.ai-mr22 {
  margin-right: 22px !important;
}

.ai-mt22 {
  margin-top: 22px !important;
}

.ai-mb22 {
  margin-bottom: 22px !important;
}

.ai-mx22 {
  margin-left: 22px !important;
  margin-right: 22px !important;
}

.ai-my22 {
  margin-top: 22px !important;
  margin-bottom: 22px !important;
}

.ai-m23 {
  margin: 23px !important;
}

.ai-ml23 {
  margin-left: 23px !important;
}

.ai-mr23 {
  margin-right: 23px !important;
}

.ai-mt23 {
  margin-top: 23px !important;
}

.ai-mb23 {
  margin-bottom: 23px !important;
}

.ai-mx23 {
  margin-left: 23px !important;
  margin-right: 23px !important;
}

.ai-my23 {
  margin-top: 23px !important;
  margin-bottom: 23px !important;
}

.ai-m24 {
  margin: 24px !important;
}

.ai-ml24 {
  margin-left: 24px !important;
}

.ai-mr24 {
  margin-right: 24px !important;
}

.ai-mt24 {
  margin-top: 24px !important;
}

.ai-mb24 {
  margin-bottom: 24px !important;
}

.ai-mx24 {
  margin-left: 24px !important;
  margin-right: 24px !important;
}

.ai-my24 {
  margin-top: 24px !important;
  margin-bottom: 24px !important;
}

.ai-m25 {
  margin: 25px !important;
}

.ai-ml25 {
  margin-left: 25px !important;
}

.ai-mr25 {
  margin-right: 25px !important;
}

.ai-mt25 {
  margin-top: 25px !important;
}

.ai-mb25 {
  margin-bottom: 25px !important;
}

.ai-mx25 {
  margin-left: 25px !important;
  margin-right: 25px !important;
}

.ai-my25 {
  margin-top: 25px !important;
  margin-bottom: 25px !important;
}

.ai-w0 {
  width: 0% !important;
}

.ai-w1 {
  width: 1% !important;
}

.ai-w2 {
  width: 2% !important;
}

.ai-w3 {
  width: 3% !important;
}

.ai-w4 {
  width: 4% !important;
}

.ai-w5 {
  width: 5% !important;
}

.ai-w6 {
  width: 6% !important;
}

.ai-w7 {
  width: 7% !important;
}

.ai-w8 {
  width: 8% !important;
}

.ai-w9 {
  width: 9% !important;
}

.ai-w10 {
  width: 10% !important;
}

.ai-w11 {
  width: 11% !important;
}

.ai-w12 {
  width: 12% !important;
}

.ai-w13 {
  width: 13% !important;
}

.ai-w14 {
  width: 14% !important;
}

.ai-w15 {
  width: 15% !important;
}

.ai-w16 {
  width: 16% !important;
}

.ai-w17 {
  width: 17% !important;
}

.ai-w18 {
  width: 18% !important;
}

.ai-w19 {
  width: 19% !important;
}

.ai-w20 {
  width: 20% !important;
}

.ai-w21 {
  width: 21% !important;
}

.ai-w22 {
  width: 22% !important;
}

.ai-w23 {
  width: 23% !important;
}

.ai-w24 {
  width: 24% !important;
}

.ai-w25 {
  width: 25% !important;
}

.ai-w26 {
  width: 26% !important;
}

.ai-w27 {
  width: 27% !important;
}

.ai-w28 {
  width: 28% !important;
}

.ai-w29 {
  width: 29% !important;
}

.ai-w30 {
  width: 30% !important;
}

.ai-w31 {
  width: 31% !important;
}

.ai-w32 {
  width: 32% !important;
}

.ai-w33 {
  width: 33% !important;
}

.ai-w34 {
  width: 34% !important;
}

.ai-w35 {
  width: 35% !important;
}

.ai-w36 {
  width: 36% !important;
}

.ai-w37 {
  width: 37% !important;
}

.ai-w38 {
  width: 38% !important;
}

.ai-w39 {
  width: 39% !important;
}

.ai-w40 {
  width: 40% !important;
}

.ai-w41 {
  width: 41% !important;
}

.ai-w42 {
  width: 42% !important;
}

.ai-w43 {
  width: 43% !important;
}

.ai-w44 {
  width: 44% !important;
}

.ai-w45 {
  width: 45% !important;
}

.ai-w46 {
  width: 46% !important;
}

.ai-w47 {
  width: 47% !important;
}

.ai-w48 {
  width: 48% !important;
}

.ai-w49 {
  width: 49% !important;
}

.ai-w50 {
  width: 50% !important;
}

.ai-w51 {
  width: 51% !important;
}

.ai-w52 {
  width: 52% !important;
}

.ai-w53 {
  width: 53% !important;
}

.ai-w54 {
  width: 54% !important;
}

.ai-w55 {
  width: 55% !important;
}

.ai-w56 {
  width: 56% !important;
}

.ai-w57 {
  width: 57% !important;
}

.ai-w58 {
  width: 58% !important;
}

.ai-w59 {
  width: 59% !important;
}

.ai-w60 {
  width: 60% !important;
}

.ai-w61 {
  width: 61% !important;
}

.ai-w62 {
  width: 62% !important;
}

.ai-w63 {
  width: 63% !important;
}

.ai-w64 {
  width: 64% !important;
}

.ai-w65 {
  width: 65% !important;
}

.ai-w66 {
  width: 66% !important;
}

.ai-w67 {
  width: 67% !important;
}

.ai-w68 {
  width: 68% !important;
}

.ai-w69 {
  width: 69% !important;
}

.ai-w70 {
  width: 70% !important;
}

.ai-w71 {
  width: 71% !important;
}

.ai-w72 {
  width: 72% !important;
}

.ai-w73 {
  width: 73% !important;
}

.ai-w74 {
  width: 74% !important;
}

.ai-w75 {
  width: 75% !important;
}

.ai-w76 {
  width: 76% !important;
}

.ai-w77 {
  width: 77% !important;
}

.ai-w78 {
  width: 78% !important;
}

.ai-w79 {
  width: 79% !important;
}

.ai-w80 {
  width: 80% !important;
}

.ai-w81 {
  width: 81% !important;
}

.ai-w82 {
  width: 82% !important;
}

.ai-w83 {
  width: 83% !important;
}

.ai-w84 {
  width: 84% !important;
}

.ai-w85 {
  width: 85% !important;
}

.ai-w86 {
  width: 86% !important;
}

.ai-w87 {
  width: 87% !important;
}

.ai-w88 {
  width: 88% !important;
}

.ai-w89 {
  width: 89% !important;
}

.ai-w90 {
  width: 90% !important;
}

.ai-w91 {
  width: 91% !important;
}

.ai-w92 {
  width: 92% !important;
}

.ai-w93 {
  width: 93% !important;
}

.ai-w94 {
  width: 94% !important;
}

.ai-w95 {
  width: 95% !important;
}

.ai-w96 {
  width: 96% !important;
}

.ai-w97 {
  width: 97% !important;
}

.ai-w98 {
  width: 98% !important;
}

.ai-w99 {
  width: 99% !important;
}

.ai-w100 {
  width: 100% !important;
}

.ai-op0 {
  opacity: 0 !important;
}

.ai-op1 {
  opacity: 0.01 !important;
}

.ai-op2 {
  opacity: 0.02 !important;
}

.ai-op3 {
  opacity: 0.03 !important;
}

.ai-op4 {
  opacity: 0.04 !important;
}

.ai-op5 {
  opacity: 0.05 !important;
}

.ai-op6 {
  opacity: 0.06 !important;
}

.ai-op7 {
  opacity: 0.07 !important;
}

.ai-op8 {
  opacity: 0.08 !important;
}

.ai-op9 {
  opacity: 0.09 !important;
}

.ai-op10 {
  opacity: 0.1 !important;
}

.ai-op11 {
  opacity: 0.11 !important;
}

.ai-op12 {
  opacity: 0.12 !important;
}

.ai-op13 {
  opacity: 0.13 !important;
}

.ai-op14 {
  opacity: 0.14 !important;
}

.ai-op15 {
  opacity: 0.15 !important;
}

.ai-op16 {
  opacity: 0.16 !important;
}

.ai-op17 {
  opacity: 0.17 !important;
}

.ai-op18 {
  opacity: 0.18 !important;
}

.ai-op19 {
  opacity: 0.19 !important;
}

.ai-op20 {
  opacity: 0.2 !important;
}

.ai-op21 {
  opacity: 0.21 !important;
}

.ai-op22 {
  opacity: 0.22 !important;
}

.ai-op23 {
  opacity: 0.23 !important;
}

.ai-op24 {
  opacity: 0.24 !important;
}

.ai-op25 {
  opacity: 0.25 !important;
}

.ai-op26 {
  opacity: 0.26 !important;
}

.ai-op27 {
  opacity: 0.27 !important;
}

.ai-op28 {
  opacity: 0.28 !important;
}

.ai-op29 {
  opacity: 0.29 !important;
}

.ai-op30 {
  opacity: 0.3 !important;
}

.ai-op31 {
  opacity: 0.31 !important;
}

.ai-op32 {
  opacity: 0.32 !important;
}

.ai-op33 {
  opacity: 0.33 !important;
}

.ai-op34 {
  opacity: 0.34 !important;
}

.ai-op35 {
  opacity: 0.35 !important;
}

.ai-op36 {
  opacity: 0.36 !important;
}

.ai-op37 {
  opacity: 0.37 !important;
}

.ai-op38 {
  opacity: 0.38 !important;
}

.ai-op39 {
  opacity: 0.39 !important;
}

.ai-op40 {
  opacity: 0.4 !important;
}

.ai-op41 {
  opacity: 0.41 !important;
}

.ai-op42 {
  opacity: 0.42 !important;
}

.ai-op43 {
  opacity: 0.43 !important;
}

.ai-op44 {
  opacity: 0.44 !important;
}

.ai-op45 {
  opacity: 0.45 !important;
}

.ai-op46 {
  opacity: 0.46 !important;
}

.ai-op47 {
  opacity: 0.47 !important;
}

.ai-op48 {
  opacity: 0.48 !important;
}

.ai-op49 {
  opacity: 0.49 !important;
}

.ai-op50 {
  opacity: 0.5 !important;
}

.ai-op51 {
  opacity: 0.51 !important;
}

.ai-op52 {
  opacity: 0.52 !important;
}

.ai-op53 {
  opacity: 0.53 !important;
}

.ai-op54 {
  opacity: 0.54 !important;
}

.ai-op55 {
  opacity: 0.55 !important;
}

.ai-op56 {
  opacity: 0.56 !important;
}

.ai-op57 {
  opacity: 0.57 !important;
}

.ai-op58 {
  opacity: 0.58 !important;
}

.ai-op59 {
  opacity: 0.59 !important;
}

.ai-op60 {
  opacity: 0.6 !important;
}

.ai-op61 {
  opacity: 0.61 !important;
}

.ai-op62 {
  opacity: 0.62 !important;
}

.ai-op63 {
  opacity: 0.63 !important;
}

.ai-op64 {
  opacity: 0.64 !important;
}

.ai-op65 {
  opacity: 0.65 !important;
}

.ai-op66 {
  opacity: 0.66 !important;
}

.ai-op67 {
  opacity: 0.67 !important;
}

.ai-op68 {
  opacity: 0.68 !important;
}

.ai-op69 {
  opacity: 0.69 !important;
}

.ai-op70 {
  opacity: 0.7 !important;
}

.ai-op71 {
  opacity: 0.71 !important;
}

.ai-op72 {
  opacity: 0.72 !important;
}

.ai-op73 {
  opacity: 0.73 !important;
}

.ai-op74 {
  opacity: 0.74 !important;
}

.ai-op75 {
  opacity: 0.75 !important;
}

.ai-op76 {
  opacity: 0.76 !important;
}

.ai-op77 {
  opacity: 0.77 !important;
}

.ai-op78 {
  opacity: 0.78 !important;
}

.ai-op79 {
  opacity: 0.79 !important;
}

.ai-op80 {
  opacity: 0.8 !important;
}

.ai-op81 {
  opacity: 0.81 !important;
}

.ai-op82 {
  opacity: 0.82 !important;
}

.ai-op83 {
  opacity: 0.83 !important;
}

.ai-op84 {
  opacity: 0.84 !important;
}

.ai-op85 {
  opacity: 0.85 !important;
}

.ai-op86 {
  opacity: 0.86 !important;
}

.ai-op87 {
  opacity: 0.87 !important;
}

.ai-op88 {
  opacity: 0.88 !important;
}

.ai-op89 {
  opacity: 0.89 !important;
}

.ai-op90 {
  opacity: 0.9 !important;
}

.ai-op91 {
  opacity: 0.91 !important;
}

.ai-op92 {
  opacity: 0.92 !important;
}

.ai-op93 {
  opacity: 0.93 !important;
}

.ai-op94 {
  opacity: 0.94 !important;
}

.ai-op95 {
  opacity: 0.95 !important;
}

.ai-op96 {
  opacity: 0.96 !important;
}

.ai-op97 {
  opacity: 0.97 !important;
}

.ai-op98 {
  opacity: 0.98 !important;
}

.ai-op99 {
  opacity: 0.99 !important;
}

.ai-op100 {
  opacity: 1 !important;
}