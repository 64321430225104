/* BEGIN Light */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/Light/OpenSans-Light.eot?v=1.1.0");
  src: url("./fonts/Light/OpenSans-Light.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/Light/OpenSans-Light.woff?v=1.1.0") format("woff"), url("./fonts/Light/OpenSans-Light.ttf?v=1.1.0") format("truetype"), url("./fonts/Light/OpenSans-Light.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: 300;
  font-style: normal;
}
/* END Light */
/* BEGIN Light Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/LightItalic/OpenSans-LightItalic.eot?v=1.1.0");
  src: url("./fonts/LightItalic/OpenSans-LightItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/LightItalic/OpenSans-LightItalic.woff?v=1.1.0") format("woff"), url("./fonts/LightItalic/OpenSans-LightItalic.ttf?v=1.1.0") format("truetype"), url("./fonts/LightItalic/OpenSans-LightItalic.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: 300;
  font-style: italic;
}
/* END Light Italic */
/* BEGIN Regular */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/Regular/OpenSans-Regular.eot?v=1.1.0");
  src: url("./fonts/Regular/OpenSans-Regular.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/Regular/OpenSans-Regular.woff?v=1.1.0") format("woff"), url("./fonts/Regular/OpenSans-Regular.ttf?v=1.1.0") format("truetype"), url("./fonts/Regular/OpenSans-Regular.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: normal;
  font-style: normal;
}
/* END Regular */
/* BEGIN Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/Italic/OpenSans-Italic.eot?v=1.1.0");
  src: url("./fonts/Italic/OpenSans-Italic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/Italic/OpenSans-Italic.woff?v=1.1.0") format("woff"), url("./fonts/Italic/OpenSans-Italic.ttf?v=1.1.0") format("truetype"), url("./fonts/Italic/OpenSans-Italic.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: normal;
  font-style: italic;
}
/* END Italic */
/* BEGIN Semibold */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/Semibold/OpenSans-Semibold.eot?v=1.1.0");
  src: url("./fonts/Semibold/OpenSans-Semibold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/Semibold/OpenSans-Semibold.woff?v=1.1.0") format("woff"), url("./fonts/Semibold/OpenSans-Semibold.ttf?v=1.1.0") format("truetype"), url("./fonts/Semibold/OpenSans-Semibold.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: 600;
  font-style: normal;
}
/* END Semibold */
/* BEGIN Semibold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/SemiboldItalic/OpenSans-SemiboldItalic.eot?v=1.1.0");
  src: url("./fonts/SemiboldItalic/OpenSans-SemiboldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/SemiboldItalic/OpenSans-SemiboldItalic.woff?v=1.1.0") format("woff"), url("./fonts/SemiboldItalic/OpenSans-SemiboldItalic.ttf?v=1.1.0") format("truetype"), url("./fonts/SemiboldItalic/OpenSans-SemiboldItalic.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: 600;
  font-style: italic;
}
/* END Semibold Italic */
/* BEGIN Bold */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/Bold/OpenSans-Bold.eot?v=1.1.0");
  src: url("./fonts/Bold/OpenSans-Bold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/Bold/OpenSans-Bold.woff?v=1.1.0") format("woff"), url("./fonts/Bold/OpenSans-Bold.ttf?v=1.1.0") format("truetype"), url("./fonts/Bold/OpenSans-Bold.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: bold;
  font-style: normal;
}
/* END Bold */
/* BEGIN Bold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/BoldItalic/OpenSans-BoldItalic.eot?v=1.1.0");
  src: url("./fonts/BoldItalic/OpenSans-BoldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/BoldItalic/OpenSans-BoldItalic.woff?v=1.1.0") format("woff"), url("./fonts/BoldItalic/OpenSans-BoldItalic.ttf?v=1.1.0") format("truetype"), url("./fonts/BoldItalic/OpenSans-BoldItalic.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: bold;
  font-style: italic;
}
/* END Bold Italic */
/* BEGIN Extrabold */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/ExtraBold/OpenSans-ExtraBold.eot?v=1.1.0");
  src: url("./fonts/ExtraBold/OpenSans-ExtraBold.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/ExtraBold/OpenSans-ExtraBold.woff?v=1.1.0") format("woff"), url("./fonts/ExtraBold/OpenSans-ExtraBold.ttf?v=1.1.0") format("truetype"), url("./fonts/ExtraBold/OpenSans-ExtraBold.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: 800;
  font-style: normal;
}
/* END Extrabold */
/* BEGIN Extrabold Italic */
@font-face {
  font-family: 'Open Sans';
  src: url("./fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?v=1.1.0");
  src: url("./fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.eot?#iefix&v=1.1.0") format("embedded-opentype"), url("./fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.woff?v=1.1.0") format("woff"), url("./fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.ttf?v=1.1.0") format("truetype"), url("./fonts/ExtraBoldItalic/OpenSans-ExtraBoldItalic.svg?v=1.1.0#OpenSansBold") format("svg");
  font-weight: 800;
  font-style: italic;
}
/* END Extrabold Italic */
